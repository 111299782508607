














import Vue from 'vue'

export default Vue.extend({
  name: 'Switcher',
  props: {
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSwitch (event) {
      this.$emit('toggleSwitcher', { checked: !this.checked, event: event })
    }
  }
})
