

















import Vue from 'vue'
export default Vue.extend({
  name: 'CirclePreloader',
  props: {
    color: {
      type: String,
      default: '#8378EC'
    },
    size: {
      type: Number,
      default: 40
    },
    depth: {
      type: Number,
      default: 4
    }
  }
})
