









































import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher.vue'
import { SLACK_SERVICE } from '@/constants/FbAutomatedRule'
import Vue from 'vue'
export default Vue.extend({
  name: 'IntegrationsUserSettings',
  components: { NewUiSwitcher },
  props: {
    status: { type: Number, required: true },
    accountName: { type: String, required: true },
    accountExternalId: { type: String, required: true },
    isEnabled: { type: Boolean, required: true },
    togglingDisabled: { type: Boolean, default: true },
    service: { type: String, default: null }
  },
  computed: {
    switcherTooltipData () {
      if (this.togglingDisabled) {
        return {
          trigger: 'hover',
          container: false,
          content: 'This ad account can\'t be connected',
          classes: ['tooltip-dark']
        }
      } else {
        return null
      }
    },
    switcherVisible () {
      return this.service !== SLACK_SERVICE
    }
  }
})
