var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{ref:"vSelect",staticClass:"v-select-timezone__select",attrs:{"popper-custom-classes":[
    'v-select-timezone__select-dropdown',
    'v-select-timezone__select-dropdown_header'
  ],"dropdown-should-open":_vm.dropdownShouldOpen,"value":_vm.modelValue || { name: 'Not Choosen', id: null },"options":_vm._timezones,"disabled":_vm.disabled,"error":_vm.error ? 'Timezone not specified' : '',"append-to-body":""},on:{"input":_vm.inputHandler},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [_c('li',{staticClass:"v-select-timezone__select-dropdown-header-wrapper"},[_c('div',{staticClass:"v-select-timezone__select-dropdown-header"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"pointerdown":function($event){$event.target && $event.target.focus()},"blur":function($event){_vm.preventClosing = false},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])])]},proxy:true},{key:"selected-option",fn:function(ref){
  var selected = ref.selected;
return [_c('div',{staticClass:"v-select-timezone__select-selected"},[_c('span',[_c('b',[_vm._v(" Timezone: ")]),_c('span',[_vm._v(" "+_vm._s(selected.name)+" ")])])])]}},{key:"option",fn:function(ref){
  var option = ref.option;
return [_c('div',{staticClass:"v-select-timezone__select-option"},[_c('span',[_vm._v(" "+_vm._s(option.name)+" ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }