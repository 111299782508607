
























import type { Timezone } from '@/constants/Timezones'
import Vue, { PropType } from 'vue'
import Modal from '@/components/Modal.vue'

export default Vue.extend({
  name: 'ModalConfirmTimezone',
  components: {
    ModalComponent: Modal
  },
  props: {
    newTimezone: {
      type: null as PropType<Timezone | null>,
      required: true
    },
    newTimezoneUser: {
      type: null as PropType<{ id: string }>
    },
    newTimezoneService: {
      type: String
    },
    confirmDisabled: {
      type: Boolean
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm', this.newTimezoneUser, this.newTimezone, this.newTimezoneService)
    }
  }
})
