var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"service-container"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title__service-create"},[_c('h2',{staticClass:"h2",attrs:{"id":_vm.scrollKey}},[_vm._v(_vm._s(_vm.serviceName))])])]),_c('div',{staticClass:"block block-type-3 padding-5 margin-top-5"},[_c('div',{staticClass:"account-container"},[_c('div',{staticClass:"service"},[_c('div',{staticClass:"about-service"},[_c('div',[_c('img',{attrs:{"alt":_vm.serviceName,"src":require(("@/assets/img/" + _vm.srcImg))}})]),_c('div',{staticClass:"about-service-text"},[_c('h3',{staticClass:"h3"},[_vm._v(_vm._s(_vm.serviceTitle))]),_c('p',{staticClass:"text-gray p-small margin-top-2"},[_vm._v(" "+_vm._s(_vm.serviceText)+" ")])])]),_c('div',{staticClass:"about-service-button d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-custom",class:{
                'btn-connect_google': _vm.keyService === 'google',
                'btn-connect_slack': _vm.keyService === 'slack'
              },attrs:{"disabled":_vm.connectButtonDisabled},on:{"click":function($event){return _vm.authOpenNewWindow(_vm.keyService)}}},[_vm._v(" "+_vm._s(({ google: 'Sign in with Google', slack: 'Add to Slack' })[_vm.keyService] || 'Connect')+" ")])])]),_c('div',{staticClass:"account"},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('BaseCirclePreloader',{attrs:{"show-preloader":_vm.loading}})],1):_vm._e(),(!_vm.loading)?_c('div',_vm._l((_vm.serverData.data),function(userAccount){return _c('div',{key:userAccount.id},[_c('user-settings',{attrs:{"accountName":userAccount.name,"accountExternalId":userAccount.external_id,"status":userAccount.status === undefined ? 1000 : userAccount.status,"is-enabled":!!userAccount.is_enabled,"toggling-disabled":_vm.toggling.includes(userAccount.id) || (_vm.getLabelType(userAccount) !== 'success' && !_vm.userAccountReconnect(userAccount)),"service":_vm.keyService},on:{"delete":function($event){return _vm.confirmDelete(userAccount.id, _vm.keyService, userAccount.name)},"toggle":function($event){return _vm.toggle(userAccount)}},scopedSlots:_vm._u([(_vm.keyService === 'linkedin')?{key:"timezone",fn:function(){return [_vm._t("timezone",null,{"id":userAccount.id,"timezone":userAccount.timezone})]},proxy:true}:null,(_vm.userAccountReconnect(userAccount) || (!_vm.userAccountReconnect(userAccount) && _vm.keyService !== 'slack'))?{key:"action",fn:function(){return [(
                      _vm.keyService !=='slack' && _vm.getLabelType(userAccount) !== 'success'
                    )?_c('BaseLabelTag',{attrs:{"tooltip-data":{
                      position: 'right',
                      trigger: 'hover',
                      container: false,
                      content: _vm.getTooltipText(userAccount),
                      classes: ['tooltip-dark']
                    },"size":'middle',"text":_vm.userAccountStatusTitle(userAccount),"type":_vm.getLabelType(userAccount),"theme":'marker-type'}}):_vm._e()]},proxy:true}:null],null,true)})],1)}),0):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }