








































































































import Vue from 'vue'
import UserSettings from '@/components/integration/IntegrationsUserSettings.vue'
import BaseLabelTag from '@/components/BaseLabelTag.vue'
import BaseCirclePreloader from '@/components/BaseCirclePreloader.vue'
import * as AccountConstants from '@/constants/AdAccount'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'AdServiceConnect',
  components: {
    UserSettings,
    BaseLabelTag,
    BaseCirclePreloader
  },
  data () {
    return {
      loading: true,
      toggling: []
    }
  },
  props: {
    serviceName: { required: true, type: String },
    srcImg: { required: true, type: String },
    serviceTitle: { required: true, type: String },
    serviceText: { required: true, type: String },
    serverData: { required: true },
    keyService: { required: true, type: String },
    scrollKey: { required: true, type: String },
    loadAdAccount: {
      required: true,
      default: {
        loadFunction: Function,
        service: String
      }
    }
  },
  computed: {
    connectButtonDisabled () {
      return (this.keyService === 'slack' && this.serverData.data)
    }
  },
  methods: {
    ...mapActions('integrations', ['patchToggle']),
    authOpenNewWindow (key) {
      this.$emit('authOpenNewWindow', key)
    },
    confirmDelete (id: string, key: string, name: string) {
      this.$emit('confirmDelete', { id, key, name })
    },
    userAccountReconnect (account) {
      return account.status === AccountConstants.STATUS_UNAUTHENTICATED || account.status ===
        AccountConstants.STATUS_HASNT_PERMISSION
    },
    userAccountStatusTitle (account) {
      return AccountConstants.STATUS_TITLE[account.status === undefined ? 1000 : account.status]
    },
    getTooltipText (account: { status: number }): string {
      return (AccountConstants.STATUS_TOOLTIP[account.status] || '').replaceAll('{service}', this.serviceName)
    },
    getLabelType (account) {
      switch (account.status) {
        case undefined:
        case AccountConstants.STATUS_IMPORTED:
        case AccountConstants.STATUS_ACTIVE:
        case AccountConstants.STATUS_ANY_ACTIVE:
        case AccountConstants.STATUS_ACTIVE_ACTIVE:
        case AccountConstants.STATUS_ENABLE:
        case AccountConstants.STATUS_ENABLED:
        case AccountConstants.STATUS_ACCEPTED:
          return 'success'
        case AccountConstants.STATUS_PENDING_RISK_REVIEW:
        case AccountConstants.STATUS_PENDING_SETTLEMENT:
        case AccountConstants.STATUS_IN_GRACE_PERIOD:
        case AccountConstants.STATUS_UPDATING_INFO:
        case AccountConstants.STATUS_PENDING:
        case AccountConstants.STATUS_PENDING_VERIFIED:
        case AccountConstants.STATUS_SELF_SERVICE_UNAUDITED:
        case AccountConstants.STATUS_PENDING_CONFIRM:
        case AccountConstants.STATUS_PENDING_CONFIRM_MODIFY:
        case AccountConstants.STATUS_WAIT_FOR_BPM_AUDIT:
        case AccountConstants.STATUS_WAIT_FOR_PUBLIC_AUTH:
        case AccountConstants.STATUS_CONTRACT_PENDING:
        case AccountConstants.STATUS_UNKNOWN:
        case AccountConstants.STATUS_CANCELED:
        case AccountConstants.STATUS_SUSPENDED:
        case AccountConstants.STATUS_BILLING_HOLD:
          return 'warning'
        case AccountConstants.STATUS_UNAUTHENTICATED:
        case AccountConstants.STATUS_UNSETTLED:
        case AccountConstants.STATUS_HASNT_PERMISSION:
        case AccountConstants.STATUS_DISABLED_ON_FACEBOOK:
        case AccountConstants.STATUS_DISABLED:
        case AccountConstants.STATUS_PENDING_CLOSURE:
        case AccountConstants.STATUS_CLOSED:
        case AccountConstants.STATUS_ANY_CLOSED:
        case AccountConstants.STATUS_NEED_RESEND_REQUEST:
        case AccountConstants.STATUS_ERROR:
        case AccountConstants.STATUS_DELETED:
        case AccountConstants.STATUS_DISABLE:
        case AccountConstants.STATUS_CONFIRM_FAIL:
        case AccountConstants.STATUS_CONFIRM_FAIL_END:
        case AccountConstants.STATUS_CONFIRM_MODIFY_FAIL:
        case AccountConstants.STATUS_LIMIT:
        case AccountConstants.STATUS_LIMIT_CODE:
        case AccountConstants.STATUS_CLOSED_CLOSED:
          return 'danger'
      }
    },
    toggle (userAccount: { id: string, is_enabled: boolean}): void {
      if (this.userAccountReconnect(userAccount)) {
        this.authOpenNewWindow(this.keyService)
        return
      }
      this.toggling = [...this.toggling, userAccount.id]
      this.patchToggle({
        service: this.keyService,
        id: userAccount.id,
        isEnabled: !userAccount.is_enabled
      }).finally(() => {
        this.toggling = this.toggling.filter(_ => _ !== userAccount.id)
        this.$emit('accountStatusToggled', { ...userAccount, is_enabled: !userAccount.is_enabled })
      })
    }
  },
  created () {
    this.loadAdAccount.loadFunction({ service: this.loadAdAccount.service })
      .then(() => {
        this.loading = false
      })
  }
})
