

















import Vue from 'vue'
import Modal from '@/components/DynamicModal.vue'

export default Vue.extend({
  name: 'ModalConfirmDelete',
  components: {
    Modal
  },
  props: {
    text: {
      required: true,
      type: Array
    },
    title: {
      required: true,
      type: String
    },
    delete: {
      required: true
    },
    params: {
      required: false,
      type: Object
    }
  },
  methods: {
    close () {
      this.$modal.hide('delete-campaign-modal')
    },
    runDelete () {
      this.delete(this.params)
    }
  }
})
