var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle-preloader-wrapper d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"circle-preloader",style:({
      'min-height': _vm.size + 'px',
      'min-width': _vm.size + 'px',
      'border-left': _vm.depth + 'px solid #EAE8FF',
      'border-top': _vm.depth + 'px solid #EAE8FF',
      'border-right': _vm.depth + 'px solid ' + _vm.color,
      'border-bottom': _vm.depth + 'px solid ' + _vm.color
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }