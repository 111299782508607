












import IntegrationsStateUserSettings from '@/components/integration/IntegrationsStateUserSettings.vue'

export default {
  name: 'ServiceIntegration',
  props: {
    auto_connect: {
      type: Boolean,
      default: false
    },
    service: {
      type: String,
      default: null
    },
    error_code: {
      default: null
    },
    scroll_to: {
      default: null
    },
    success: {
      default: null
    }
  },
  components: {
    IntegrationsStateUserSettings
  }
}
