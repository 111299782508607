













import Vue from 'vue'

export default Vue.extend({
  name: 'BaseLabelTag',
  props: {
    text: {
      type: String,
      default: 'label',
      required: true
    },
    type: {
      type: String,
      default: 'info'
    },
    tooltipData: {
      required: false,
      default: null
    },
    size: {
      type: String,
      default: 'small'
    },
    theme: {
      type: String,
      default: 'button-type'
    }
  },
  components: {},
  methods: {}
})
