export default {
  scrollToElementWithSidebar (element: string | HTMLElement, sidebarHeight = 50): void {
    if (typeof (element) === 'string') {
      element = document.getElementById(element)
    }
    if (element) {
      element.scrollIntoView(true)
      setTimeout(() => {
        const { top } = (element as HTMLElement).getBoundingClientRect()
        if (top < sidebarHeight) {
          window.scrollBy(0, -1 * (sidebarHeight - top + 5))
        }
      }, 800) // hack for scroll ending time
    }
  }
}
