



















































import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { Timezone, TIMEZONES, Timezones } from '@/constants/Timezones'
import Vue, { PropType } from 'vue'

type Data = {
  search: string
  preventClosing: boolean
  vSelectContext: unknown
}

export default Vue.extend({
  name: 'VSelectTimezone',
  components: {
    VSelect
  },
  data: (): Data => ({
    search: '',
    preventClosing: false,
    vSelectContext: null
  }),
  props: {
    modelValue: {
      type: null as PropType<Timezone | null>
    },
    timezones: {
      type: Array as PropType<Timezones>,
      default: () => TIMEZONES
    },
    error: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    _timezones () {
      return this.search
        ? this.timezones.filter(_ => _.name.toLowerCase().includes(this.lowercaseSearch))
        : this.timezones
    },
    lowercaseSearch () {
      return this.search.toLowerCase()
    }
  },
  methods: {
    dropdownShouldOpen ({ noDrop, open, mutableLoading }): boolean {
      return this.preventClosing || (noDrop ? false : open && !mutableLoading)
    },
    inputHandler (newValue: Timezone) {
      if (this.vSelectContext) {
        setTimeout(() => {
          this.vSelectContext.open = false
        })
      }
      this.$emit('update:modelValue', newValue)
    }
  },
  mounted () {
    this.vSelectContext = this.$refs.vSelect?.$children[0]
  }
})
