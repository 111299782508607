
























import Vue from 'vue'
import BasePagePreloader from '../components/BasePagePreloader.vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'DynamicModalCustom',
  props: {
    name: {
      type: String
    },
    title: {
      type: String,
      default: 'Modal Title'
    },
    width: {
      type: String,
      default: '600'
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    shiftY: {
      type: Number,
      default: 0.2
    },
    cancelButton: {
      type: Boolean,
      default: true
    },
    new_footer: {
      type: Boolean,
      default: false
    }
  },
  components: { BasePagePreloader },
  computed: {
    ...mapState('interests', ['modalLoading'])
  },
  methods: {
    hide (name : string) {
      (this as any).$modal.hide(name)
    }
  }
})
